import React, { useState, useContext } from "react";
import styles from "./OpenShift.module.scss";
import { API_URL } from "../../../config";
import AuthContext from "../../../context/AuthContext";
import TeploControlWorker from "./TeploControlWorker";

function OpenShift() {
  const { authTokens } = useContext(AuthContext);
  const [fullname, setFullname] = useState("");
  const [isShiftOpened, setIsShiftOpened] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fullname,
      flag_complete: false,
    };

    try {
      const response = await fetch(`${API_URL}/TControl/smena/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setIsShiftOpened(true);
        alert("Смена успешно открыта !");
      } else {
        console.error("Ошибка при создании смены");
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  return (
    <>
      {isShiftOpened ? (
        <TeploControlWorker />
      ) : (
        <div className={styles.openShiftContainer}>
          <h2 className={styles.title}>Открытие смены</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <label className={styles.label}>
              ФИО:
              <input
                type="text"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                required
                className={styles.input}
              />
            </label>
            <button type="submit" className={styles.button}>
              Открыть смену
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default OpenShift;
