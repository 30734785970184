import React, { useState, useEffect, useContext } from "react";
import styles from "./styles/MonthlyIndicators.module.scss";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";
import * as XLSX from "xlsx";

function MonthlyIndicators() {
  const { authTokens } = useContext(AuthContext);
  const [selectedBoiler, setSelectedBoiler] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [data, setData] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [shifts, setShifts] = useState({});
  const [boilerRooms, setBoilerRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Функция для экспорта в Excel
  const exportToExcel = () => {
    if (filteredData.length === 0) {
      alert("Нет данных для экспорта");
      return;
    }

    // Подготовка данных для экспорта
    const exportData = filteredData.map((item, index) => {
      const { resource, note } = getSensorDetails(item.sensor_id);
      const consumption = calculateConsumption(item, index, filteredData);
      
      return {
        "Смена": item.smena_id ? shifts[item.smena_id] || item.smena_id : "-",
        "Энергоресурс": resource || "-",
        "Характеристика ПУ": note || "-",
        "Показания ПУ": item.consumption,
        "Время работы ПУ": item.operation_time,
        "Потребление": consumption.toFixed(2),
        "Дата измерения": new Date(item.created_at).toLocaleDateString("ru-RU")
      };
    });

    // Создание рабочей книги Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    
    // Добавление листа в книгу
    XLSX.utils.book_append_sheet(wb, ws, "Показатели");
    
    // Генерация файла и скачивание
    const fileName = `Показатели_${selectedResource}_${new Date().toISOString().split('T')[0]}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const fetchBoilerRooms = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка загрузки котельных");
      }

      const result = await response.json();
      setBoilerRooms(result);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchSensors = async () => {
    try {
      const response = await fetch(`${API_URL}/TControl/sensors/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка загрузки датчиков");
      }

      const result = await response.json();
      setSensors(result);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchData = async () => {
    if (!selectedBoiler || !selectedResource) {
      setData([]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const apiUrl = selectedDate
        ? `${API_URL}/TControl/daily_dynamic_settings/get/${selectedResource}/parameters/${selectedDate}/`
        : `${API_URL}/TControl/daily_dynamic_settings/`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        throw new Error("Ошибка загрузки данных");
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchShifts = async () => {
      const smenaIds = [...new Set(data.map((item) => item.smena_id))].filter(
        (id) => id != null
      );
      const newShifts = { ...shifts };

      for (const id of smenaIds) {
        if (!newShifts[id]) {
          try {
            const response = await fetch(`${API_URL}/TControl/smena/${id}/`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`,
              },
            });

            if (!response.ok) throw new Error("Ошибка загрузки смены");

            const shiftData = await response.json();
            newShifts[id] = shiftData.fullname;
          } catch (error) {
            console.error(`Смена ${id}:`, error);
            newShifts[id] = "Неизвестно";
          }
        }
      }

      setShifts((prev) => ({ ...prev, ...newShifts }));
    };

    if (data.length > 0) fetchShifts();
  }, [data, authTokens.access]);

  useEffect(() => {
    fetchBoilerRooms();
    fetchSensors();

    const currentDate = new Date().toISOString().split("T")[0];
    setSelectedDate(currentDate);
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedBoiler, selectedResource, selectedDate]);

  const calculateConsumption = (currentData, index, filteredData) => {
    if (index === filteredData.length - 1) return 0;
    
    const nextIndex = index + 1;
    const nextData = filteredData[nextIndex];
    
    if (currentData.sensor_id !== nextData.sensor_id) return 0;
    
    return currentData.consumption - nextData.consumption;
  };

  const getSensorDetails = (sensorId) => {
    const sensor = sensors.find((s) => s.id === sensorId);
    return sensor
      ? {
          resource: sensor.sensor_type,
          boilerhouseId: sensor.boilerhouse_id,
          number: sensor.number,
          note: sensor.note,
        }
      : {};
  };

  const handleResourceChange = (e) => {
    setSelectedResource(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filteredData = data
    .filter(
      (item) =>
        !selectedBoiler ||
        getSensorDetails(item.sensor_id).boilerhouseId ===
          Number(selectedBoiler)
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Месячные показатели</h3>
      <div className={styles.controls}>
        <select
          className={styles.dropdown}
          value={selectedBoiler}
          onChange={(e) => setSelectedBoiler(e.target.value)}
        >
          <option value="">Выберите котельную</option>
          {boilerRooms.map((boiler) => (
            <option key={boiler.pk} value={boiler.pk}>
              {boiler.address}
            </option>
          ))}
        </select>

        <select
          className={styles.dropdown}
          value={selectedResource}
          onChange={handleResourceChange}
        >
          <option value="">Выберите энергоресурс</option>
          <option value="electricity">Электричество</option>
          <option value="water">Водоснабжение</option>
          <option value="gas">Газ</option>
        </select>

        <input
          type="date"
          className={styles.dateInput}
          value={selectedDate}
          onChange={handleDateChange}
        />

        {/* Кнопка экспорта в Excel */}
        <button 
          className={styles.exportButton}
          onClick={exportToExcel}
          disabled={filteredData.length === 0}
        >
          Экспорт в Excel
        </button>
      </div>

      {loading && <p className={styles.loadingMessage}>Загрузка данных...</p>}
      {error && <p className={styles.errorMessage}>Ошибка: {error}</p>}

      {selectedBoiler && selectedResource && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>Смена</th>
              <th className={styles.tableHeader}>Энергоресурс</th>
              <th className={styles.tableHeader}>Характеристика ПУ</th>
              <th className={styles.tableHeader}>Показания ПУ</th>
              <th className={styles.tableHeader}>Время работы ПУ</th>
              <th className={styles.tableHeader}>Потребление</th>
              <th className={styles.tableHeader}>Дата измерения</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              const { resource, note, number } = getSensorDetails(item.sensor_id);
              const consumption = calculateConsumption(item, index, filteredData);
              
              return (
                <tr key={item.pk} className={styles.tableRow}>
                  <td className={styles.tableCell}>
                    {item.smena_id ? shifts[item.smena_id] || item.smena_id : "-"}
                  </td>
                  <td className={styles.tableCell}>{resource || "-"}</td>
                  <td className={styles.tableCell}>{note || "-"}</td>
                  <td className={styles.tableCell}>{item.consumption}</td>
                  <td className={styles.tableCell}>{item.operation_time}</td>
                  <td className={styles.tableCell}>
                    {consumption.toFixed(2)}
                  </td>
                  <td className={styles.tableCell}>
                    {new Date(item.created_at).toLocaleDateString("ru-RU")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default MonthlyIndicators;