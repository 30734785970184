import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../context/AuthContext";
import { API_URL } from "../../../../config";
import styles from "./styles/EquipmentWork.module.scss";

function EquipmentWork() {
  const { authTokens } = useContext(AuthContext);
  const [boilerRooms, setBoilerRooms] = useState([]);
  const [selectedBoilerRoom, setSelectedBoilerRoom] = useState(null);
  const [boilers, setBoilers] = useState([]);
  const [pumps, setPumps] = useState([]);

  useEffect(() => {
    const fetchBoilerRooms = async () => {
      try {
        const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        setBoilerRooms(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Ошибка при загрузке котельных:", error);
        setBoilerRooms([]);
      }
    };
    fetchBoilerRooms();
  }, [authTokens]);

  useEffect(() => {
    if (selectedBoilerRoom) {
      const fetchBoilersAndPumps = async () => {
        try {
          const [boilersResponse, pumpsResponse] = await Promise.all([
            fetch(`${API_URL}/TControl/boiler/`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`,
              },
            }),
            fetch(`${API_URL}/TControl/boilerhousepump/`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`,
              },
            }),
          ]);

          const boilersData = await boilersResponse.json();
          const pumpsData = await pumpsResponse.json();

          console.log(boilersData);
          console.log(pumpsData);

          setBoilers(
            Array.isArray(boilersData)
              ? boilersData.filter((boiler) => boiler.boilerhouse_id === selectedBoilerRoom.pk)
              : []
          );
          setPumps(
            Array.isArray(pumpsData)
              ? pumpsData.filter((pump) => pump.boilerhouse_id === selectedBoilerRoom.pk)
              : []
          );
        } catch (error) {
          console.error("Ошибка при загрузке котлов и насосов:", error);
          setBoilers([]);
          setPumps([]);
        }
      };
      fetchBoilersAndPumps();
    }
  }, [selectedBoilerRoom, authTokens]);

  return (
    <div className={styles.container}>
      <div className={styles.dropdownGroup}>
        <label htmlFor="boilerRoom" className={styles.label}>Выберите котельную</label>
        <select
          className={styles.dropdown}
          id="boilerRoom"
          value={selectedBoilerRoom?.pk || ""}
          onChange={(e) => {
            const selected = boilerRooms.find((room) => room.pk === parseInt(e.target.value));
            setSelectedBoilerRoom(selected || null);
          }}
        >
          <option value="">Выберите...</option>
          {boilerRooms.map((room) => (
            <option key={room.pk} value={room.pk}>
              {room.address}
            </option>
          ))}
        </select>
      </div>

      {selectedBoilerRoom && (
        <div className={styles.tableContainer}>
          <h3 className={styles.tableTitle}>Котлы</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeader}>Номер</th>
                <th className={styles.tableHeader}>Тип</th>
                <th className={styles.tableHeader}>Статус</th>
                <th className={styles.tableHeader}>Мощность (Гкал/ч)</th>
              </tr>
            </thead>
            <tbody>
              {boilers.length > 0 ? (
                boilers.map((boiler) => (
                  <tr key={boiler.pk} className={styles.tableRow}>
                    <td className={styles.tableCell}>{boiler.number}</td>
                    <td className={styles.tableCell}>{boiler.type || "-"}</td>
                    <td className={styles.tableCell}>{boiler.status || "-"}</td>
                    <td className={styles.tableCell}>{boiler.installed_power || "-"}</td>
                  </tr>
                ))
              ) : (
                <tr className={styles.tableRow}>
                  <td className={styles.tableCell} colSpan="4">Нет доступных котлов</td>
                </tr>
              )}
            </tbody>
          </table>

          <h3 className={styles.tableTitle}>Насосы</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeader}>Номер</th>
                <th className={styles.tableHeader}>Тип</th>
                <th className={styles.tableHeader}>Статус</th>
                <th className={styles.tableHeader}>Производительность (м³/ч)</th>
                <th className={styles.tableHeader}>Мощность (кВт)</th>
              </tr>
            </thead>
            <tbody>
              {pumps.length > 0 ? (
                pumps.map((pump) => (
                  <tr key={pump.pk} className={styles.tableRow}>
                    <td className={styles.tableCell}>{pump.number}</td>
                    <td className={styles.tableCell}>{pump.type || "-"}</td>
                    <td className={styles.tableCell}>{pump.status || "-"}</td>
                    <td className={styles.tableCell}>{pump.pump_output}</td>
                    <td className={styles.tableCell}>{pump.power}</td>
                  </tr>
                ))
              ) : (
                <tr className={styles.tableRow}>
                  <td className={styles.tableCell} colSpan="5">Нет доступных насосов</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EquipmentWork;