import React, { useState, useContext } from "react";
import useCurrentShift from "../../utils/useCurrentShift";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";
import styles from "./styles/ShiftClosing.module.scss";

function ShiftClosing() {
  const { authTokens } = useContext(AuthContext);
  const { currentShift, loading, error, refetch } = useCurrentShift();
  const [confirmClose, setConfirmClose] = useState(false);

  const closeShift = async () => {
    if (!currentShift) return;
    console.log(currentShift);

    try {
      const response = await fetch(
        `${API_URL}/TControl/smena/${currentShift.pk}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({
            fullname: currentShift.fullname,
            flag_complete: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка при закрытии смены");
      }

      const updatedShift = await response.json();
      alert(`Смена №${updatedShift.shift_number} закрыта успешно!`);
      setConfirmClose(false);
      refetch();
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className={styles.container}>
      {loading && <div className={styles.loadingMessage}>Загрузка...</div>}
      {error && <div className={styles.errorMessage}>{error}</div>}

      {!loading && currentShift && (
        <div className={styles.shiftInfo}>
          <h3 className={styles.shiftHeader}>Текущая смена</h3>
          <p className={styles.shiftText}>Смена №{currentShift.shift_number}</p>
          <p className={styles.shiftText}>ФИО: {currentShift.fullname}</p>
          <button
            className={`${styles.button} ${styles.primaryButton}`}
            onClick={() => setConfirmClose(true)}
          >
            Закрыть смену
          </button>
        </div>
      )}

      {!loading && !currentShift && (
        <p className={styles.noShiftMessage}>Открытых смен нет</p>
      )}

      {confirmClose && (
        <div className={styles.modal}>
          <h4 className={styles.modalHeader}>Вы уверены, что хотите закрыть смену?</h4>
          <p className={styles.modalText}>Смена №{currentShift?.shift_number}</p>
          <div className={styles.modalButtons}>
            <button
              className={`${styles.button} ${styles.primaryButton}`}
              onClick={closeShift}
            >
              Да, закрыть смену
            </button>
            <button
              className={`${styles.button} ${styles.secondaryButton}`}
              onClick={() => setConfirmClose(false)}
            >
              Отмена
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShiftClosing;