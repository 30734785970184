import React, { useState, useEffect, useContext } from "react";
import useCurrentShift from "../../utils/useCurrentShift";
import { API_URL } from "../../../../config";
import AuthContext from "../../../../context/AuthContext";
import styles from "./styles/DayEntries.module.scss";

const DayEntries = () => {
  const { authTokens } = useContext(AuthContext);
  const [selectedBoiler, setSelectedBoiler] = useState("");
  const [boilers, setBoilers] = useState([]);
  const [sensors, setSensors] = useState(null);
  const { currentShift } = useCurrentShift();
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const fetchBoilers = async () => {
      try {
        const response = await fetch(`${API_URL}/TControl/boilerhouse/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        setBoilers(data);
      } catch (error) {
        console.error("Ошибка при загрузке котельных:", error);
      }
    };

    fetchBoilers();
  }, []);

  const fetchSensors = async (boilerId) => {
    try {
      const response = await fetch(`${API_URL}/TControl/sensors/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });
      const data = await response.json();
      console.log(data)
      const filteredSensors = data.filter(
        (sensor) => sensor.boilerhouse_id === parseInt(boilerId, 10)
      );

      const groupedSensors = filteredSensors.reduce((acc, sensor) => {
        if (!acc[sensor.sensor_type]) {
          acc[sensor.sensor_type] = [];
        }
        acc[sensor.sensor_type].push(sensor);
        return acc;
      }, {});

      setSensors(groupedSensors);
    } catch (error) {
      console.error("Ошибка при загрузке датчиков:", error);
    }
  };

  const handleBoilerChange = (e) => {
    const boilerId = e.target.value;
    setSelectedBoiler(boilerId);
    if (boilerId) {
      fetchSensors(boilerId);
    } else {
      setSensors(null);
    }
  };

  const handleInputChange = (sensorId, field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [sensorId]: {
        ...prev[sensorId],
        [field]: value,
      },
    }));
  };

  const handleSave = (type, sensorId) => {
    const consumption = inputValues[sensorId]?.consumption || 0;
    const hours = inputValues[sensorId]?.hours || "00";
    const minutes = inputValues[sensorId]?.minutes || "00";
    const seconds = inputValues[sensorId]?.seconds || "00";
    const operationTime = `${hours.padStart(2, "0")}:${minutes.padStart(
      2,
      "0"
    )}:${seconds.padStart(2, "0")}`;

    const data = {
      smena_id: currentShift.pk,
      sensor_id: sensorId,
      consumption: parseFloat(consumption),
      operation_time: operationTime,
      note: "Нет",
    };

    console.log("Отправляемые данные:", data);

    fetch(`${API_URL}/TControl/daily_dynamic_settings/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(errorMessage.join("\n"));
        }
        return response.json();
      })
      .then((result) => {
        console.log("Ответ сервера:", result);
        alert("Данные успешно сохранены!");
      })
      .catch((error) => {
        console.error("Ошибка при сохранении:", error);
        alert(`Ошибка: ${error.message}`);
      });
  };

  const renderSensorFields = (type, sensors) => {
    return sensors.map((sensor) => (
      <div key={sensor.id} className={styles.sensorBlock}>
        <h4 className={styles.sensorTitle}>Прибор учета: {sensor.note}</h4>
        <div className={styles.row}>
          <input
            placeholder="Показание ПУ"
            className={styles.input}
            value={inputValues[sensor.id]?.consumption || ""}
            onChange={(e) =>
              handleInputChange(sensor.id, "consumption", e.target.value)
            }
          />
          <input
            placeholder="Время работы (часы)"
            className={styles.input}
            value={inputValues[sensor.id]?.hours || ""}
            onChange={(e) =>
              handleInputChange(sensor.id, "hours", e.target.value)
            }
          />
          <input
            placeholder="Время работы (минуты)"
            className={styles.input}
            value={inputValues[sensor.id]?.minutes || ""}
            onChange={(e) =>
              handleInputChange(sensor.id, "minutes", e.target.value)
            }
          />
        </div>
        <button
          className={styles.saveButton}
          onClick={() => handleSave(type, sensor.id)}
        >
          Сохранить
        </button>
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.dropdownGroup}>
          <label className={styles.label}>Выберите котельную</label>
          <select
            className={styles.dropdown}
            value={selectedBoiler}
            onChange={handleBoilerChange}
          >
            <option value="">Выберите...</option>
            {boilers.map((boiler) => (
              <option key={boiler.pk} value={boiler.pk}>
                {boiler.address}
              </option>
            ))}
          </select>
        </div>
      </div>

      {sensors && (
        <>
          {Object.keys(sensors).map((type) => (
            <section key={type} className={styles.section}>
              <h2 className={styles.sectionTitle}>{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
              {renderSensorFields(type, sensors[type])}
            </section>
          ))}
        </>
      )}
    </div>
  );
};

export default DayEntries;